import React from "react";
import { Card, Col, Row, Accordion } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "./style.css";
import img1 from "../assets/image/hk1.jpg";
import img2 from "../assets/image/hm2.jpg";
import img3 from "../assets/image/hk2.jpg";

// Component for each job vacancy in /jobs
const JobItem = ({ job }) => {
  const { id_jobs, title, division, location, jobType, placement, description, qualification} = job;

  const images = [img1, img2, img3]

  const history = useHistory();

  const handleClick = () => {
    history.push(`/job/${id_jobs}/form`);
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Col xs={12} className="job-item-container mt-2">
        <Accordion>
          <Card className="job-item no-border">
            <Accordion.Header className="job-header d-flex justify-content-between align-items-center">
              <div className="flex-grow-1 ">
                <div className="d-flex flex-wrap job-info">
                  <span className="division-item me-2">{division}</span><strong>·</strong>
                  <span className="job-type-item ms-2 me-2">{jobType}</span><strong>·</strong>
                  <span className="placement-item ms-2">{placement}</span>
                </div>
                
                <Card.Title className="title mb-0">{title}</Card.Title>
              </div>
              <span className="job-info d-flex align-items-center ms-1 me-2">
                  <i className="bi bi-geo-alt me-1"></i>
                  {location}
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <Card.Body className="p-2 ps-0 mb-3">
                <Row className="accordion-content">
                  <Col xs={12} md={3} className="d-flex flex-column image-stack-container">
                    {/* Stacking three images without any margin */}
                    {images && images.slice(0, 3).map((image, index) => (
                      <img key={index} src={image} alt={`job-${index}`} className="stacked-images" />
                    ))}
                  </Col>
                  <Col xs={12} md={9} className="description-col">
                    <div className="job-description mt-2">
                      <h5 className="mb-4">A Typical Day :</h5>
                      {description && description.map((desc, index) => {
                        // Check if the description contains a colon
                        if (desc.includes(":")) {
                          const [boldPart, regularPart] = desc.split(":");
                          return (
                            <p className="mb-3" key={index}>
                              <strong>· <span className="me-2"></span>{boldPart}:</strong>
                              {regularPart}
                            </p>
                          );
                        } else {
                          // If there's no colon, render the description normally
                          return (
                            <p className="mb-3" key={index}>
                              <strong>· </strong><span className="me-2"></span>{desc}
                            </p>
                          );
                        }
                      })}
                      <h5 className="mt-5 mb-3">Qualification :</h5>
                      {qualification && qualification.map((qual, index) => (
                        <p className="mb-3" key={index}><strong>· </strong><span className="me-2"></span>{qual}</p>
                      ))}
                    </div>
                    <div
                      className="apply-button"
                      onClick={handleClick}
                    >
                      Apply
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Body>
          </Card>
        </Accordion>
      </Col>
    </>
  );
};

export default JobItem;
