import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import MyNavbar from "../components/MyNavbar";
import jobsImage from "../assets/hero2-1.png";

function FAQ() {
  const [jobs, setJobs] = useState([
    {
      id_jobs: 1,
      title: "IT Officer",
      division: "IT Operation",
      location: "Jakarta",
      jobType: "Fixed Term (Contract)",
      placement: "Corporate AHG",
    },
    {
      id_jobs: 2,
      title: "Guest Service Officer",
      division: "Operation",
      location: "Jakarta",
      jobType: "Permanent",
      placement: "Ashley Hotel Tanah Abang",
    },
    {
      id_jobs: 3,
      title: "AC Technician",
      division: "Daily Worker",
      location: "Jakarta",
      jobType: "Permanent",
      placement: "Corporate AHG",
    },
    {
      id_jobs: 4,
      title: "Guest Service Officer",
      division: "Operation",
      location: "Jakarta",
      jobType: "Permanent",
      placement: "YellowBee Hotel Tangerang",
    },
    {
      id_jobs: 5,
      title: "Corporate HR & Legal Officer",
      division: "Human Resources",
      location: "Jakarta",
      jobType: "Fixed Term (Contract)",
      placement: "Corporate AHG",
    },
    {
      id_jobs: 6,
      title: "Room Attendant",
      division: "Daily Worker",
      location: "Jakarta",
      jobType: "Permanent",
      placement: "Corporate AHG",
    },
  ]);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFunction, setSelectedFunction] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedJobType, setSelectedJobType] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFunctionSelect = (eventKey) => {
    setSelectedFunction(eventKey);
  };

  const handleLocationSelect = (eventKey) => {
    setSelectedLocation(eventKey);
  };

  const handleJobTypeSelect = (eventKey) => {
    setSelectedJobType(eventKey);
  };

  const filteredJobs = jobs.filter((job) => {
    return (
      (searchTerm === "" ||
        job.title.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (selectedFunction === "" || job.division === selectedFunction) &&
      (selectedLocation === "" || job.location === selectedLocation) &&
      (selectedJobType === "" || job.jobType === selectedJobType)
    );
  });
  return (
    <>
      <div className="jobs-banner">
        <Container style={{ paddingTop: "4%" }}>
          <Row className="align-items-center h-100 mx-auto row-width">
            <Col md={8} className="text-start">
              <img src={jobsImage} alt="Hero" className="jobs-image" />
            </Col>
            <Col md={4} className="text-md-end mb-5">
              <h1 style={{ fontWeight: 700, fontSize: "32px" }}>
                Your Destination Clue
              </h1>
              <p>Things everybody wants to know about</p>
            </Col>
          </Row>
        </Container>
      </div>

      <Container
        className="section section-padding"
        style={{ paddingBottom: "10%" }}
      >
        {/* Search and Filter Bar */}
        <div className="search-filter-bar-wrapper">
          <Container>
            <Row
              className="search-filter-bar mb-4 mx-auto"
              style={{ width: "90%" }}
            >
              <Col md={10}>
                <Row>
                  <InputGroup>
                    <Form.Control
                      placeholder="Type Your Dream Job"
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                  </InputGroup>
                </Row>
                <Row className="mt-3">
                  <Col md={10} className="d-flex justify-content-between">
                    <DropdownButton
                      id="dropdown-basic-button"
                      title="Select Function"
                      className="me-2"
                      onSelect={handleFunctionSelect}
                    >
                      <Dropdown.Item eventKey="">All Functions</Dropdown.Item>
                      <Dropdown.Item eventKey="IT Operation">
                        IT Operation
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Operation">
                        Operation
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Daily Worker">
                        Daily Worker
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Human Resources">
                        Human Resources
                      </Dropdown.Item>
                    </DropdownButton>
                    <DropdownButton
                      id="dropdown-basic-button"
                      title="Select Location"
                      className="me-2"
                      onSelect={handleLocationSelect}
                    >
                      <Dropdown.Item eventKey="">All Locations</Dropdown.Item>
                      <Dropdown.Item eventKey="Jakarta">Jakarta</Dropdown.Item>
                    </DropdownButton>
                    <DropdownButton
                      id="dropdown-basic-button"
                      title="Select Job Type"
                      onSelect={handleJobTypeSelect}
                    >
                      <Dropdown.Item eventKey="">All Job Types</Dropdown.Item>
                      <Dropdown.Item eventKey="Fixed Term (Contract)">
                        Fixed Term (Contract)
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="Permanent">
                        Permanent
                      </Dropdown.Item>
                    </DropdownButton>
                  </Col>
                </Row>
              </Col>
              <Col
                md={2}
                style={{ rowSpan: 2 }}
                className="d-flex align-items-center justify-content-center"
              >
                <Button variant="warning" className="clear-button">
                  Clear
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>

      <Container></Container>
    </>
  );
}

export default FAQ;
