// HomeScreen.js
import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  Dropdown,
  DropdownButton,
  CardBody,
  Card,
  Image,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import "../App.css";
import hero from "../assets/image/fo1.jpg";
import sec1 from "../assets/image/team1.jpg";
import decor1 from "../assets/image/decor1.png";
import abstractBG1 from "../assets/image/abstract-bg-1.png";
import decor2 from "../assets/image/decoration2.png";
import sec3img from "../assets/image/hm2.jpg";
import carousel1 from "../assets/image/hk1.jpg";
import carousel2 from "../assets/image/fo2.jpg";
import carousel3 from "../assets/image/hm3.jpg";
import carousel4 from "../assets/image/eng1.jpg";
import carousel5 from "../assets/image/hm1.jpg";
import carousel6 from "../assets/image/work2b.jpg";
import carousel7 from "../assets/image/hk2.jpg";
import sec5img from "../assets/image/work1.jpg";
import decor3 from "../assets/image/decor3.png";
//==HOTELS==//
import hotelas11 from "../assets/image/hotels/as11.jpg";
import hotelas12 from "../assets/image/hotels/as12.jpg";
import hotelas13 from "../assets/image/hotels/as13.jpg";
import hotelas21 from "../assets/image/hotels/as21.jpg";
import hotelas22 from "../assets/image/hotels/as22.jpg";
import hotelas23 from "../assets/image/hotels/as23.jpg";
import hotelas31 from "../assets/image/hotels/as31.jpg";
import hotelas32 from "../assets/image/hotels/as32.jpg";
import hotelas33 from "../assets/image/hotels/as33.jpg";
import hotelas41 from "../assets/image/hotels/as41.jpg";
import hotelas42 from "../assets/image/hotels/as42.jpg";
import hotelas43 from "../assets/image/hotels/as43.png";
import hotelju11 from "../assets/image/hotels/ju11.jpg";
import hotelju21 from "../assets/image/hotels/ju21.jpg";
import hotelju22 from "../assets/image/hotels/ju22.jpg";
import hotelju23 from "../assets/image/hotels/ju23.jpg";
import hotelst11 from "../assets/image/hotels/st11.jpg";
import hotelst12 from "../assets/image/hotels/st12.jpg";
import hotelst13 from "../assets/image/hotels/st13.jpg";
import hotelyb11 from "../assets/image/hotels/yb11.jpg";
import hotelyb12 from "../assets/image/hotels/yb12.jpg";
import hotelyb13 from "../assets/image/hotels/yb13.jpg";
import hotelyb21 from "../assets/image/hotels/yb21.jpg";
import hotelyb22 from "../assets/image/hotels/yb22.jpg";
import hotelyb23 from "../assets/image/hotels/yb23.jpg";
//==HOTELS==//
//==ICONS==//
import as1 from "../assets/icons/as1.png";
import as2 from "../assets/icons/as2.png";
import as3 from "../assets/icons/as3.png";
import as4 from "../assets/icons/as4.png";
import as5 from "../assets/icons/as5.png";
import ju1 from "../assets/icons/ju1.png";
import ju2 from "../assets/icons/ju2.png";
import st1 from "../assets/icons/st1.png";
import yb1 from "../assets/icons/yb1.png";
import yb2 from "../assets/icons/yb2.png";
//==ICONS==//
import decorBunga from "../assets/image/bunga-vintage.png"
import core1 from "../assets/icons/collab.png";
import core2 from "../assets/icons/agile.png";
import core3 from "../assets/icons/respect.png";
import core4 from "../assets/icons/enthu.png";
import image1 from "../assets/img/fo.jpg";
import image5 from "../assets/img/career2.jpeg";
import image3 from "../assets/img/career3.jpeg";
import image4 from "../assets/img/career4.jpeg";
import image2 from "../assets/img/career1.jpeg";
import image6 from "../assets/img/juno.jpg";
import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

const HomeScreen = () => {
  //data dummy
  const [jobs, setJobs] = useState([
    {
      id_jobs: 1,
      title: "Room Attendant",
      division: "Housekeeping",
      location: "Jakarta",
      jobType: "Daily Worker",
      placement: "Juno Jatinegara",
      description: [
        "Room Refresh : Enter guest rooms, make beds, and clean surfaces.",
        "Spot Checks : Ensure maintenance issues are reported promptly.",
        "Guest Interactions : Greet guests warmly and assist with requests.",
        "Final Touches : Ensure room looks perfect before leaving."
      ],
      qualification: [
        "Minimum Vocational High School.",
        "6 months experience in a similar role."
      ]
    },
    {
      id_jobs: 2,
      title: "IT Officer",
      division: "Information Technology",
      location: "Jakarta",
      jobType: "Fixed Term (Contract)",
      placement: "Head Office AHG",
      description: [
        "Monitor and maintain IT systems including networks and software.",
        "Troubleshoot hardware and software issues effectively.",
        "Assist staff with IT-related problems in a timely manner."
      ],
      qualification: [
        "Diploma in Information Technology or related field.",
        "Experience with troubleshooting and network support.",
        "Good problem-solving skills."
      ]
    },
    {
      id_jobs: 3,
      title: "Guest Service Officer",
      division: "Front Office",
      location: "Jakarta",
      jobType: "Fixed Term (Contract)",
      placement: "Ashley Tanah Abang",
      description: [
        "Greet and welcome guests upon arrival.",
        "Handle guest inquiries and reservations.",
        "Assist with check-ins and check-outs efficiently."
      ],
      qualification: [
        "Hospitality diploma or equivalent.",
        "1-year experience in front office or similar role.",
        "Strong communication skills."
      ]
    },
    {
      id_jobs: 4,
      title: "AC Technician",
      division: "Engineering",
      location: "Jakarta",
      jobType: "Daily Worker",
      placement: "Ashley Wahid Hasyim",
      description: [
        "Install and maintain air conditioning systems.",
        "Diagnose and repair issues with HVAC systems.",
        "Ensure systems meet safety and efficiency standards."
      ],
      qualification: [
        "Vocational certification in HVAC systems.",
        "At least 1-year experience in AC maintenance.",
        "Ability to read and understand technical manuals."
      ]
    },
    {
      id_jobs: 5,
      title: "Guest Service Officer",
      division: "Front Office",
      location: "Jakarta",
      jobType: "Fixed Term (Contract)",
      placement: "Yellow Bee Tangerang",
      description: [
        "Greet guests warmly and ensure they feel welcome.",
        "Handle guest complaints and provide solutions.",
        "Coordinate with housekeeping and maintenance as needed."
      ],
      qualification: [
        "Previous experience in hospitality preferred.",
        "Ability to handle stressful situations professionally.",
        "Good verbal communication skills."
      ]
    },
    {
      id_jobs: 6,
      title: "Corporate HR & Legal Officer",
      division: "Human Resources",
      location: "Jakarta",
      jobType: "Fixed Term (Contract)",
      placement: "Head Office AHG",
      description: [
        "Assist in HR policy development and compliance.",
        "Handle legal documentation and maintain records.",
        "Support recruitment and onboarding processes."
      ],
      qualification: [
        "Bachelor's degree in Law or Human Resources.",
        "Experience in HR and legal support.",
        "Strong attention to detail."
      ]
    }
  ]);
  //image gallery
  const images = [image1, image2, image3, image4, image5, image6];  

  //for carousel images

  const carouselImages = [
    carousel1,
    carousel2,
    carousel3,
    carousel4,
    carousel5,
    carousel6,
    carousel7
];

  const swiperRef3 = useRef(null);
    
  const totalSlides = carouselImages.length;

  const [progress, setProgress] = useState((1 / totalSlides) * 100);
  const [currentIndex, setCurrentIndex] = useState(0);

  const updateProgress = (newIndex) => {
    // Calculate progress based on the new index.
    let progressValue;
    if (newIndex === totalSlides - 1) {
      progressValue = 100; // If it's the last slide, set progress to 100%
    } else {
      progressValue = ((newIndex + 1) / totalSlides) * 100; // Start with 1/7 for the first slide
    }
    setProgress(progressValue);
  };

  const handleNext = () => {
    let newIndex = currentIndex + 1;
    if (newIndex >= totalSlides) {
      newIndex = 0; // Loop back to the start
    }
    setCurrentIndex(newIndex);
    updateProgress(newIndex);
    swiperRef3.current.swiper.slideNext();
  };

  const handlePrev = () => {
    let newIndex = currentIndex - 1;
    if (newIndex < 0) {
      newIndex = totalSlides - 1; // Loop back to the end
    }
    setCurrentIndex(newIndex);
    updateProgress(newIndex);
    swiperRef3.current.swiper.slidePrev();
  };

  //for our brands
  const hotels = [
    {
      name: "Ashley Wahid Hasyim",
      code: "as1",
      description: "Located in the center of business and entertainment area in Jakarta, Ashley Hotel Jakarta Wahid Hasyim presents elegant themed rooms for those of you who want to get rid of fatigue while enjoying the atmosphere of the capital.",
      images: [hotelas11, hotelas12, hotelas13],
    },
    {
      name: "Ashley Sabang",
      code: "as2",
      description: "Escape plain reality and enter a stylish French atmosphere in the middle of the metropolis, where you’ll be greeted with cheery interior design and warm service. Now, this is what we call a fashionable retreat!",
      images: [hotelas21, hotelas22, hotelas23],
    },
    {
      name: "Ashley Tanah Abang",
      code: "as3",
      description: "Contemporarily built with lush and serene ambiance, Ashley Tanah Abang is a haven near Thamrin's prime spots, designed for family getaways and restorative business trips.",
      images: [hotelas31, hotelas32, hotelas33],
    },
    {
      name: "Ashley Tang",
      code: "as4",
      description: "With a glamorous décor and authentic boutique chinese oriental concept, Ashley Tang displays an aura of high-end sophistication.",
      images: [hotelas41, hotelas42, hotelas43],
    },
    {
      name: "Ashley Tugu Tani",
      code: "as5",
      description: "Step into a world of refined a timeless style at our 19th century British concept hotel in heart of Jakarta.",
      images: [], // No images available yet for Ashley Tugu Tani
    },
    {
      name: "Juno Tanah Abang",
      code: "ju1",
      description: "With a truly minimalistic design, Juno is perfect for dynamic travelers who dare to explore the city’s largest textile market, Tanah Abang.",
      images: [hotelju11], // Only one image available
    },
    {
      name: "Juno Jatinegara",
      code: "ju2",
      description: "Juno Hotel Jatinegara is inspired by the budget traveler’s dynamic lifestyle. With direct access to a shopping center, Juno Hotel Jatinegara is near places of interest, such as the popular Bali Mester Market as well as Pasar Jaya Jatinegara.",
      images: [hotelju21, hotelju22, hotelju23],
    },
    {
      name: "Stanley Wahid Hasyim",
      code: "st1",
      description: "From the lobby to the rooms, the highly accessible Stanley Wahid Hasyim is recognized as the perfect embodiment of affordable luxury. The accommodation is conveniently situated near Monas, Grand Indonesia, and Sarinah Thamrin.",
      images: [hotelst11, hotelst12, hotelst13],
    },
    {
      name: "Yellow Bee Tangerang",
      code: "yb1",
      description: "Situated at the center of Tangerang City and near Jakarta-Tangerang toll road, Yellow Bee is an excellent choice for anyone looking for comfort and convenience — especially when it’s just 35 minutes from the international airport.",
      images: [hotelyb11, hotelyb12, hotelyb13],
    },
    {
      name: "Yellow Bee Tanah Abang",
      code: "yb2",
      description: "Aside from its modern rooms, Yellow Bee Tanah Abang is also just minutes from Tanah Abang market, hence making it an ideal choice for budget travelers, backpackers, and businessmen who seek simple, straightforward comfort.",
      images: [hotelyb21, hotelyb22, hotelyb23],
    },
  ];
  

  const hotelBrands = [
    { id: 1, name: "Ashley Wahid Hasyim", img: as1 },
    { id: 2, name: "Ashley Sabang", img: as2 },
    { id: 3, name: "Ashley Tanah Abang", img: as3 },
    { id: 4, name: "Ashley Tang Menteng", img: as4 },
    { id: 5, name: "Ashley Tugu Tani", img: as5 },
    { id: 6, name: "Stanley Wahid Hasyim", img: st1 },
    { id: 7, name: "Juno Tanah Abang", img: ju1 },
    { id: 8, name: "Juno Jatinegara", img: ju2 },
    { id: 9, name: "Yellow Bee Tangerang", img: yb1 },
    { id: 10, name: "Yellow Bee Tanah Abang", img: yb2 },
  ];

  const [currentHotelIndex, setCurrentHotelIndex] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Functions for handling arrows
  const handleNextHotel = () => {
    const nextIndex = (currentHotelIndex + 1) % hotels.length;
    setCurrentHotelIndex(nextIndex);
    setCurrentImageIndex(0);
  };

  const handlePrevHotel = () => {
    const prevIndex =
      (currentHotelIndex - 1 + hotels.length) % hotels.length;
    setCurrentHotelIndex(prevIndex);
    setCurrentImageIndex(0);
  };

  const handleNextImage = () => {
    const currentHotel = hotels[currentHotelIndex];
    const nextImageIndex =
      (currentImageIndex + 1) % currentHotel.images.length;
    if (nextImageIndex === 0) {
      handleNextHotel(); // Move to next hotel when images end
    } else {
      setCurrentImageIndex(nextImageIndex);
    }
  };

  const handlePrevImage = () => {
    const currentHotel = hotels[currentHotelIndex];
    const prevImageIndex =
      (currentImageIndex - 1 + currentHotel.images.length) %
      currentHotel.images.length;
    if (prevImageIndex === currentHotel.images.length - 1) {
      handlePrevHotel(); // Move to previous hotel when images end
    } else {
      setCurrentImageIndex(prevImageIndex);
    }
  };

  const history = useHistory();

  const swiperRef = useRef(null);
  const swiperRef2 = useRef(null);
  const [showNextPrev, setShowNextPrev] = useState(false);
  const [showNextPrevJobs, setShowNextPrevJobs] = useState(false);
  const [startCount, setStartCount] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFunction, setSelectedFunction] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedJobType, setSelectedJobType] = useState("");

  const handleDiscoverJobs = () => {
    history.push("/jobs");  // Navigate to /jobs
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    console.log("search term : ", event.target.value);
  };

  const handleFunctionSelect = (event) => {
    setSelectedFunction(event.target.value);
    console.log("selected function : ", event.target.value);
  };

  const handleLocationSelect = (event) => {
    setSelectedLocation(event.target.value);
    console.log("selected location : ", event.target.value);
  };

  const handleJobTypeSelect = (event) => {
    setSelectedJobType(event.target.value);
    console.log("selected jobtype : ", event.target.value);
  };

  //search bar
  const handleSearch = () => {
    history.push(
      `/jobs?searchTerm=${searchTerm}&selectedFunction=${selectedFunction}&selectedLocation=${selectedLocation}&selectedJobType=${selectedJobType}`
    );
    window.scrollTo(0, 0);
  };

  //swiper component open jobs
  const handleJobs = () => {
    history.push("/jobs");
    window.scrollTo(0, 0);
  };

  //swiper jobs on a particular job
  const handleClick = (id_jobs) => {
    history.push(`/job/${id_jobs}`);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    updateProgress(currentIndex);

    NativeFancybox.bind("[data-fancybox]", {
      youtube: {
        controls: 0,
        showinfo: 1,
      },
    });

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setStartCount(true);
        }
      },
      { threshold: 0.5 }
    );

    if (swiperRef.current) {
      observer.observe(swiperRef.current);
    }

    if (swiperRef2.current) {
      observer.observe(swiperRef2.current);
    }

    const handleResize = () => {
      if (window.innerWidth < 1024) {
        setShowNextPrev(true);
      } else {
        setShowNextPrev(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
      NativeFancybox.unbind("[data-fancybox]");
      if (swiperRef.current) {
        observer.unobserve(swiperRef.current);
      }
      if (swiperRef2.current) {
        observer.unobserve(swiperRef2.current);
      }
    };
  }, []);

  return (
    <>
      {/* Hero */}
      <div className="banner-jobs-background-wrapper">
        <div className="hero-jobs-background pb-5">
            <div className="background-overlay" style={{ backgroundImage: `url(${hero})` }}></div>
            <div className="container-lg hero-section pb-5">
              <Row className="align-items-center justify-content-left">
                <Col
                  xs={12}
                  md={6}
                  className="text-container text-md-start text-center"
                >
                  <h1 className="hero-title">
                    Careers at <br /> Ashley.
                  </h1>
                  <p className="hero-text">
                    Experience unparalleled growth and excellence <br /> in the 
                    world of hospitality.
                  </p>
                  <button className="discover-btn" onClick={handleDiscoverJobs}>Discover Jobs</button>
                </Col>
              </Row>
            </div>
          </div>

          {/* Search and Filter Bar New */}
          <div className="search-filter-bar-trans p-2" style={{ display: "none" }}>
          <Row className="justify-content-center">
            <Col lg={12} xl={12}>
              <Form
                className="row align-items-center justify-content-center"
                action="/jobs"
                method="get"
              >
                <input type="hidden" name="search" value="true" />
                {/* Search */}
                <Col xs={12} md={3} className="padding-left-0">
                  <InputGroup>
                    <Form.Control
                      type="text"
                      name="key"
                      id="key"
                      maxLength="50"
                      placeholder="Type Your Dream Job"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      autoComplete="off"
                      className="no-border radius-50 font-sm search-input-comp bg-teal"
                    />
                  </InputGroup>
                </Col>
                <Col xs={12} md={7} className="padding-left-0">
                  <Row>
                    {/* Department */}
                    <Col
                      xs={12}
                      sm={4}
                      className="d-flex flex-row align-items-center padding-right-0 "
                    >
                      <InputGroup className=" inner-group radius-50 d-flex flex-row justify-content-center margin-top-0  bg-teal">
                        <InputGroup.Text className="no-border bg-teal">
                          <i className="bi bi-grid"></i>
                        </InputGroup.Text>
                        <Form.Select
                          name="directorates"
                          id="directorates"
                          onChange={handleFunctionSelect}
                          value={selectedFunction}
                          className="select2 no-border font-sm bg-teal"
                          data-placeholder="Select Function"
                        >
                          <option
                            className="bg-white"
                            value=""
                            disabled
                            selected
                          >
                            Department
                          </option>
                          <option value="">All Department</option>
                          <option value="Information Technology">
                            Information Technology
                          </option>
                          <option value="Front Office">Front Office</option>
                          <option value="Engineering">Engineering</option>
                          <option value="Housekeeping">Housekeeping</option>
                          <option value="Human Resources">
                            Human Resources
                          </option>
                        </Form.Select>
                      </InputGroup>
                    </Col>
                    {/* Location */}
                    <Col
                      xs={6}
                      sm={4}
                      className=" d-flex flex-row align-items-center pe-0 "
                    >
                      <InputGroup className="inner-group radius-50 d-flex flex-row justify-content-center margin-top-0 bg-teal">
                        <InputGroup.Text className="no-border  bg-teal">
                          <i className="bi bi-geo-alt"></i>
                        </InputGroup.Text>
                        <Form.Select
                          name="location"
                          id="location"
                          onChange={handleLocationSelect}
                          value={selectedLocation}
                          className="select2 no-border font-sm  bg-teal"
                          data-placeholder="Select Location"
                        >
                          <option value="" disabled selected>
                            Location
                          </option>
                          <option value="">All Locations</option>
                          <option value="Jakarta">Jakarta</option>
                        </Form.Select>
                      </InputGroup>
                    </Col>
                    {/* Job Type */}
                    <Col
                      xs={6}
                      sm={4}
                      className=" d-flex flex-row align-items-center padding-left-12 "
                    >
                      <InputGroup className=" inner-group radius-50 d-flex flex-row justify-content-center margin-top-0  bg-teal">
                        <InputGroup.Text className="no-border  bg-teal">
                          <i className="bi bi-briefcase"></i>
                        </InputGroup.Text>
                        <Form.Select
                          name="commitment"
                          id="jobtype"
                          onChange={handleJobTypeSelect}
                          value={selectedJobType}
                          className="select2 no-border font-sm  bg-teal"
                          data-placeholder="Select Job Type"
                        >
                          <option
                            value=""
                            disabled
                            selected
                            className="color-gray"
                          >
                            Job Type
                          </option>
                          <option value="">All Job Types</option>
                          <option value="Fixed Term (Contract)">
                            Fixed Term (Contract)
                          </option>
                          <option value="Permanent">Permanent</option>
                        </Form.Select>
                      </InputGroup>
                    </Col>
                  </Row>
                </Col>
                {/* Button */}
                <Col
                  xs={12}
                  md={2}
                  sm={6}
                  className="d-flex align-items-center justify-content-center padding-left-0"
                >
                  <div
                    type="submit"
                    className="w-100 text-center button-temp search-button"
                    onClick={handleSearch}
                  >
                    <i className="bi bi-search me-2 fs-6"></i>
                    <span className="search-text">Search</span>
                  </div>
                </Col>
              </Form>
            </Col>
          </Row>
          </div>
      </div>

      {/* Content Section */}
      <div>
        {/* Section 1 Component */}
        <div className="section-1-wrapper bg-white container-lg position-relative">
          <Row className="align-items-center justify-content-between">
            {/* Image Column */}
            <Col xs={12} md={6}>
              <img src={sec1} alt="Team" className="sec-1-img rounded-image" />
            </Col>

            {/* Text Column */}
            <Col xs={12} md={6} className="sec-1-text">
              <h2 className="sec-1-title">A Culture <br /> of Collaboration</h2>
              <p className="sec-1-description">
                At Ashley Hotel Group, we believe in the power of teamwork and a positive working environment. 
                Our vibrant, enthusiastic teams work together to create exceptional experiences for our guests.
              </p>
            </Col>
          </Row>

          {/* Decorative Dots */}
          <img src={decor1} alt="Decoration" className="sec-1-decoration" />
        </div>

        {/* Section 2 Component */}
        <div
          className="section-wrapper-bg position-relative"
          style={{ 
            backgroundImage: `url(${abstractBG1})`,
            backgroundSize: '190%',
            backgroundPosition: '50% 65%',
            backgroundRepeat: 'no-repeat',
            padding: '3rem 0',
            textAlign: 'center'
          }}
        >
          <div className="container-lg">
            <Row className="justify-content-center">
              <Col xs={12} md={10} lg={9}>
                <h2 className="sec-2-title">Our Mission</h2>
                <p className="sec-2-description">
                  At Ashley Hotel Group, we are driven by our passion for exceptional hospitality.
                  Founded in 2015, our mission is to build and operate traveler-friendly hotels that offer 
                  more than just a stay — we create lasting memories. Whether it's budget-friendly 
                  accommodations or luxurious 4-star experiences, our hotels in Jakarta and Tangerang 
                  reflect our commitment to quality service.
                </p>
                <p className="sec-2-highlight">
                  <strong>We love serving you</strong>, and we believe our people are the core of that promise.
                  Join our team and contribute to our continued growth in hospitality as we expand across the Jakarta area.
                </p>
              </Col>
            </Row>
            {/* Decorative Ornaments */}
            <img src={decor2} alt="Ornaments" className="sec-2-decoration" />
            </div>
        </div>

        {/* Section 3  Component */}
        <div className="sec-3-wrapper">
          <Row className="h-100">
            {/* Left Column - Image */}
            <Col xs={12} md={7} className="p-0 sec-3-img">
              <div
                className="image-background"
                style={{ backgroundImage: `url(${sec3img})` }}
              ></div>
            </Col>

            {/* Right Column - Content */}
            <Col
              xs={12}
              md={5}
              className="d-flex align-items-center justify-content-center sec-3-content"
            >
              <div className="sec-3-text">
                <h2 className="sec-3-title">Growing Together</h2>
                <p className="sec-3-description">
                  We believe that growth happens through guidance and shared
                  knowledge. Every day is an opportunity to learn, improve, and
                  achieve new heights together.
                </p>
              </div>
            </Col>
          </Row>
        </div>

        {/* Section 4 Carousel */}
        <div className="carousel-container">
          <Swiper
            ref={swiperRef3}
            slidesPerView={4.3}
            spaceBetween={10}
            freeMode={true}
            grabCursor={true}
            loop={true}
            onSlideChange={(swiper) => {
              let newIndex = swiper.realIndex;
              setCurrentIndex(newIndex);
              updateProgress(newIndex);
            }}
          >
            {carouselImages.map((image, index) => (
              <SwiperSlide key={index}>
                <div className="carousel-image-container">
                  <img src={image} alt={`Slide ${index + 1}`} className="carousel-image" />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <Row className="align-items-center mt-4 d-flex">
            <Col xs={11} md={11} className="tracker-container">
              <div className="light-grey-tracker"></div>
              <div className="dark-grey-tracker" style={{ width: `${progress}%` }}></div>
            </Col>
            <Col xs={1} md={1} className="d-flex justify-content-end">
              <div className="carousel-arrow me-2" onClick={handlePrev}>
                <FaChevronLeft />
              </div>
              <div className="carousel-arrow" onClick={handleNext}>
                <FaChevronRight />
              </div>
            </Col>
          </Row>
        </div>

        {/**  Section 5 Component Our Brands*/}
        <Container fluid className="section-5 mt-5">
          <Row className="align-items-center">
            <Col md={6}>
              {/* Image Swiper with Image Arrows */}
              <div className="position-relative">
                <Button
                  variant="light"
                  className="position-absolute top-50 start-0 translate-middle-y"
                  onClick={handlePrevImage}
                >
                  &lt;
                </Button>
                <img
                  src={hotels[currentHotelIndex].images[currentImageIndex]}
                  alt={`hotel-${currentHotelIndex}-${currentImageIndex}`}
                  className="img-fluid w-100"
                  style={{ borderRadius: "15px" }}
                />
                <Button
                  variant="light"
                  className="position-absolute top-50 end-0 translate-middle-y"
                  onClick={handleNextImage}
                >
                  &gt;
                </Button>
              </div>
            </Col>
            <Col md={6}>
              {/* Hotel Info */}
              <h2 className="hotel-name">{hotels[currentHotelIndex].name}</h2>
              <p className="hotel-description">
                {hotels[currentHotelIndex].description}
              </p>
            </Col>
          </Row>
          <Row className="mt-4 align-items-center justify-content-center">
            {/* Hotel Arrow with Indicators */}
            <Button
              variant="outline-secondary"
              className="me-2"
              onClick={handlePrevHotel}
            >
              &lt;
            </Button>
            <span className="hotel-indicator">
              {currentHotelIndex + 1} / {hotels.length}
            </span>
            <Button
              variant="outline-secondary"
              className="ms-2"
              onClick={handleNextHotel}
            >
              &gt;
            </Button>
          </Row>
        </Container>

        {/** Section 6 */}
        <div className="bring-ideas-section position-relative">
          <img src={decor3} alt="Decoration" className="decor-image" />
          <div className="content-wrapper">
            <Row className="align-items-center">
              <Col md={6} className="py-3">
                <div className="sec-6-text">
                  <h2 className="sec-6-title">Bring Your Ideas to Life</h2>
                  <p className="sec-6-description">
                    Innovation thrives when creativity meets opportunity. At Ashley Hotel Group, we celebrate fresh ideas and diverse perspectives, knowing that the best solutions often come from thinking outside the box. Here, enthusiasm isn't just encouraged—it's essential.
                  </p>
                </div>
              </Col>
              <Col md={6} className="py-3">
                <img
                  src={sec5img}
                  alt="Working Together"
                  className="work-image"
                />
              </Col>
            </Row>
          </div>
        </div>

        {/* Section 7 Component */}
        <div className="section-7-wrapper bg-white container-lg position-relative">
          <Row>
            <Col xs={12}>
              <h2 className="sec-7-title text-center">Core Values</h2>
            </Col>
          </Row>
          <Row className="align-items-start justify-content-between core-container">
            <Col xs={12} md={3} className="value-card">
              <Image src={core1} alt="Collaborate Icon" className="value-icon mb-3" />
              <h3 className="value-title"><strong className="capital-core">C</strong>ollaborate</h3>
              <p className="value-description">
                is the key to provide a memorable guest experience and to motivate employees to reach a common goal.
              </p>
            </Col>
            <Col xs={12} md={3} className="value-card">
              <Image src={core2} alt="Agile Icon" className="agile-icon mb-3" />
              <h3 className="value-title"><strong className="capital-core">A</strong>gile</h3>
              <p className="value-description">
                AHG team swiftly adapts to market, customer, and owner expectations. Agile teams are business-savvy and constantly seek improvement.
              </p>
            </Col>
            <Col xs={12} md={3} className="value-card">
              <Image src={core3} alt="Respect Icon" className="value-icon mb-3" />
              <h3 className="value-title"><strong className="capital-core">R</strong>espect</h3>
              <p className="value-description">
                is the foundation of communication and relationships with colleagues, guests, management, and owners.
              </p>
            </Col>
            <Col xs={12} md={3} className="value-card">
              <Image src={core4} alt="Enthusiastic Icon" className="value-icon mb-3" />
              <h3 className="value-title"><strong className="capital-core">E</strong>nthusiastic</h3>
              <p className="value-description">
                Enthusiasm is contagious. A happy team will make the guests happy. A happy team will make a positive working environment.
              </p>
            </Col>
          </Row>
          {/* Decorative Dots */}
          <img src={decor1} alt="Decoration" className="sec-1-decoration" />
        </div>

        {/* Swiper Jobs Title */}
        <div className="bg-light2 section" style={{ paddingBottom: "30%", display:"none" }}>
          <div className="container-lg">
            <h2 className="temp-title">Check Out Available Jobs</h2>
          </div>

          {/* Swiper Jobs */}

          <div
            ref={swiperRef2}
            className="container-lg overflow-hidden position-relative"
          >
            <Row className="justify-content-center">
              <Col xs={12}>
                <Swiper
                  modules={[Navigation, Autoplay]}
                  spaceBetween={0}
                  slidesPerView={3}
                  // slidesPerGroup={1}
                  // centeredSlides={true}
                  // centerInsufficientSlides={true}
                  loop={true}
                  autoplay={{
                    delay: 1, // Minimize delay to make it appear continuous
                    disableOnInteraction: false,
                  }}
                  speed={6000} // Adjust speed to control the smoothness of the scrolling
                  allowTouchMove={false} // Prevent user interaction from stopping the autoplay
                  cssMode={false} // Ensure smooth scrolling behavior
                  grabCursor={false} // No cursor change
                  freeMode={true} // Enables free mode
                  freeModeMomentum={false} // Disable momentum for smooth continuous effect
                  // initialSlide={0}
                  breakpoints={{
                    1: {
                      slidesPerView: 1,
                      slidesPerGroup: 1,
                      centeredSlides: true,
                    },
                    240: {
                      slidesPerView: 1,
                      slidesPerGroup: 1,
                      centeredSlides: true,
                    },
                    640: {
                      slidesPerView: 2,
                      slidesPerGroup: 1,
                      centeredSlides: false,
                    },
                    768: {
                      slidesPerView: 2,
                      slidesPerGroup: 1,
                      centeredSlides: false,
                    },
                    1024: {
                      slidesPerView: 3,
                      slidesPerGroup: 1,
                      centeredSlides: false,
                    },
                  }}
         
                  className="swiper-jobs-wrapper-container"
                  style={{ overflow: "visible" }}
                >
                  {jobs.map((job) => (
                    <SwiperSlide key={job.id_jobs} className="swiper-wrapper">
                      <div className="swiper-jobs-container">
                        <div className="swiper-jobs-content">
                          <Card
                            className="job-item x-pad"
                            onClick={() => handleClick(job.id_jobs)}
                          >
                            <Card.Body>
                              <Row>
                                <Col xs={12}>
                                  <Card.Title className="title">
                                    {job.title}
                                  </Card.Title>
                                </Col>
                              </Row>
                              <Row>
                                
                                <Col xs={4} md={4} className="content">
                                  <div className="location d-flex align-items-center ms-1 mb-2">
                                    <i className="bi bi-geo-alt me-1"></i>
                                    <p className="mb-0">{job.location}</p>
                                  </div>
                                </Col>
                                <Col xs={8} md={8}>
                                  <div className="job-type-item mb-1 text-end">
                                    {job.jobType}
                                  </div>
                                </Col>
                                <Col xs={12} md={12} className="order-3">
                                  <div className="placement-item-home text-start ">
                                    {job.placement}
                                  </div>
                                </Col>
                                <Col xs={12} md={12} className="order-4">
                                  <strong className="division-item">
                                    {job.division}
                                  </strong>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
             
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeScreen;
