import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import { debounce } from "lodash";
import axios from "../axios";

function JobDetail() {
  const { id } = useParams();
  var idx = id - 1;
  const [jobs, setJobs] = useState([
    {
      id_jobs: 1,
      title: "IT Officer",
      division: "Information Technology",
      location: "Jakarta",
      jobType: "Fixed Term (Contract)",
      placement: "Corporate AHG",
    },
    {
      id_jobs: 2,
      title: "Guest Service Officer",
      division: "Front Office",
      location: "Jakarta",
      jobType: "Permanent",
      placement: "Ashley Hotel Tanah Abang",
    },
    {
      id_jobs: 3,
      title: "AC Technician",
      division: "Engineering",
      location: "Jakarta",
      jobType: "Permanent",
      placement: "Corporate AHG",
    },
    {
      id_jobs: 4,
      title: "Guest Service Officer",
      division: "Front Office",
      location: "Jakarta",
      jobType: "Permanent",
      placement: "YellowBee Hotel Tangerang",
    },
    {
      id_jobs: 5,
      title: "Corporate HR & Legal Officer",
      division: "Human Resources",
      location: "Jakarta",
      jobType: "Fixed Term (Contract)",
      placement: "Corporate AHG",
    },
    {
      id_jobs: 6,
      title: "Room Attendant",
      division: "Housekeeping",
      location: "Jakarta",
      jobType: "Permanent",
      placement: "Corporate AHG",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [job, setJob] = useState(null);

  const history = useHistory();

  const handleBack = () => {
    history.push(`/jobs`);
    window.scrollTo(0, 0);
  };

  const handleClick = () => {
    history.push(`/job/${id}/form`);
    window.scrollTo(0, 0);
  };

  const getJobDetail = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/job/{id}`);
      setJob(response.data);
    } catch (error) {
      console.log("Error Jobs Detauk: ", error);
    } finally {
      setLoading(false);
    }
  };

  const debounceGetJobDetail = debounce(getJobDetail, 500);

  //   useEffect(() => {
  //     debounceGetJobDetail();
  //   }, []);

  return (
    <>
      {/* Header Job Info */}
      {/* <div className="header-detail-bg">
        <div className="container-lg header-detail d-flex justify-content-between align-items-center flex-column flex-sm-row">
          <div>
            <h1>{jobs[idx].title}</h1>
            <div className="job-meta d-flex mt-3 mb-2">
              <div className="location-detail d-flex align-items-center ">
                <i className="bi bi-geo-alt me-1"></i>
                <p className="mb-0">{jobs[idx].location}</p>
                <p className="mb-0 ms-2 me-2">{"-"}</p>
              </div>
              <div className="placement-detail">{jobs[idx].placement}</div>
            </div>
            <div className="text-start text-sm-end mt-2 mt-md-0 d-flex">
              <strong className="division-detail">{jobs[idx].division}</strong>
              <p className="text-white mb-0 ms-2 me-2">{"/"}</p>
              <div className="job-type-detail mb-0">{jobs[idx].jobType}</div>
            </div>
          </div>
          <div>
            <div className="button btn-apply1" onClick={handleClick}>
              Apply For This Job
            </div>
          </div>
        </div>
      </div> */}

      {/* Small Header Job Info */}
      <div className="header-detail-bg">
        <div className="container-lg header-detail-sm d-flex justify-content-between align-items-center flex-column flex-sm-row">
          <div>
            <p>Position</p>
            <h1>{jobs[idx].title}</h1>
          </div>
        </div>
      </div>

      <Container className="job-detail">
        <Row className="d-flex justify-content-between">
          <Col xs={12} md={3} className="order-1 order-md-2">
            <div className="apply pe-0 pe-md-4">
              <div className="d-flex justify-content-md-start justify-content-start mt-md-5 mt-1 mb-md-3 mb-1">
                <div
                  className="button btn-apply1 d-inline"
                  onClick={handleClick}
                >
                  Apply Now
                </div>
              </div>

              <div className="d-flex flex-md-column flex-row">
                <div className="d-block">
                  <div className="mt-4 me-4 me-md-0">
                    <p className="m-0 job-detail-info-text">Department</p>
                    <strong className="division-detail">
                      {jobs[idx].division}
                    </strong>
                  </div>
                  <div className="mt-4">
                    <p className="m-0 job-detail-info-text">Location</p>
                    <div className="d-flex flex-row location-detail">
                      {" "}
                      <i className="d-block bi bi-geo-alt me-1 icon-bold"></i>
                      <p className="mb-0">{jobs[idx].location}</p>
                    </div>
                  </div>
                </div>
                <div className="d-block">
                  <div className="mt-4 me-4 me-md-0">
                    <p className="m-0 job-detail-info-text">Placement</p>
                    <div className="placement-detail">
                      {jobs[idx].placement}
                    </div>
                  </div>
                  <div className="mt-4">
                    <p className="m-0 job-detail-info-text">Job Type</p>
                    <div className="job-type-detail mb-0">
                      {jobs[idx].jobType}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} md={8} className="order-2 order-md-1">
            {/* Job Description */}
            <div className="job-description padding-right-12">
              <h3 style={{ marginTop: "0px" }}>Responsibilities</h3>
              <ul>
                <li>
                  Work collaboratively with product managers to translate
                  requirements into reusable fast web modules.
                </li>
                <li>
                  Partner and work closely with Backend developer for seamless
                  API integration.
                </li>
                <li>
                  Develop well-written front-end code for new product features
                  as well as maintain code and fix defects within the existing
                  code base.
                </li>
                <li>
                  Apply best-practices and coding patterns to ensure your
                  solutions are maintainable, modular of high code-quality and
                  work well across browsers and devices.
                </li>
                <li>
                  Building scalable, highly available, resilient, extendable,
                  secured architecture to provide a significant boost to the
                  business
                </li>
              </ul>
              <h3>Requirements</h3>
              <ul>
                <li>4+ years of experience in frontend development.</li>
                <li>Experience in working with product-based development.</li>
                <li>
                  Experience with HTML5, JavaScript, TypeScript, front-end
                  technologies like React, Angular, Vue, Redux, Webpack
                </li>
                <li>Deep understanding of how the web works at scale.</li>
                <li>Experience in developing and maintaining PWA’s.</li>
                <li>
                  Strong communication and writing skills of both Bahasa
                  Indonesia & English
                </li>
              </ul>
              <div className="d-block d-md-none">
                <div className="d-flex justify-content-end mt-4 mb-0">
                  <div
                    className="button btn-back me-3"
                    onClick={handleBack}
                    style={{ cursor: "pointer" }}
                  >
                    Back
                  </div>
                  <div
                    className="button btn-apply"
                    style={{ cursor: "pointer" }}
                    onClick={handleClick}
                  >
                    Apply
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {/* Button */}
        {/* <Row>
          
          <div className="d-flex justify-content-end mt-5 mb-3">
            <div
              className="button btn-back me-4"
              onClick={handleBack}
              style={{ cursor: "pointer" }}
            >
              Back
            </div>
            <div
              className="button btn-apply"
              style={{ cursor: "pointer" }}
              onClick={handleClick}
            >
              Apply
            </div>
          </div>
        </Row> */}
      </Container>
    </>
  );
}

export default JobDetail;
