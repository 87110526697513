import React, { useEffect, useState } from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Offcanvas,
  Button,
} from "react-bootstrap";
import { useLocation, Link } from "react-router-dom";
import "./style.css";
import logo from "../assets/logo_teal.png";
import white from "../assets/logo_white.png";

function MyNavbar() {
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const stickyThreshold = 40;
  const [isTealPage, setIsTealPage] = useState(false);
  const [isFormPage, setIsFormPage] = useState(false);

  const [expanded, setExpanded] = useState(false);

  let isHomePage = location.pathname === "/";
  let isJobsPage = location.pathname === "/jobs";

  useEffect(() => {
    const path = location.pathname;
    const jobPathPattern = /^\/job\/\d+$/;
    const formPathPattern = /^\/job\/\d+\/form$/;

    //jobs, job, and form has teal header, if isTealPage is false, use nav-top class
    if (
      path === "/jobs" ||
      jobPathPattern.test(path)
    ) {
      setIsTealPage(true);
      setIsFormPage(false);
    } else if (formPathPattern.test(path)){
      setIsFormPage(true);
      setIsTealPage(false);
    } else {
      setIsTealPage(false);
      setIsFormPage(false);
    }

    //when scrolled = navbar has teal background and white text
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const isScrolled = currentScrollY > 0;

      setScrolled(isScrolled);

      if (currentScrollY > lastScrollY) {
        setShowNavbar(false);
      } else {
        setShowNavbar(true);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  // set nav text color to white in page that have teal header
  const getColor = () => {
    const path = location.pathname;
    const jobPathPattern = /^\/job\/\d+$/;
    const formPathPattern = /^\/job\/\d+\/form$/;

    if (scrolled) {
      return "#ffffff";
    }

    switch (location.pathname) {
      case "/jobs":
        return "#ffffff";
      case "/job":
        return "#ffffff";
      case "/form":
        return "#00414e";
      default:
        if (jobPathPattern.test(path)) {
          return "#ffffff";
        } else if (formPathPattern.test(path)){
          return "#00414e";
        }
        return "#fff";
    }
  };

  //set to use white text logo when in page that have teal banner
  const getLogo = () => {
    const path = location.pathname;
    const jobPathPattern = /^\/job\/\d+$/;
    const formPathPattern = /^\/job\/\d+\/form$/;
    if (
      // path === "/jobs" ||
      // path === "/faq" ||
      // jobPathPattern.test(path) ||
      formPathPattern.test(path) ||
      scrolled
    ) {
      return logo;
    }
    return white;
  };

  const getNavbarClass = () => {
    if (window.scrollY > stickyThreshold) {
      return showNavbar ? "sticky visible" : "sticky hidden";
    } else {
      return "non-sticky";
    }
  };

  return (
    <>
      {!expanded && (
        <Navbar
          collapseOnSelect
          expand="lg"
          expanded={expanded}
          className={`my-navbar ${getNavbarClass()} ${
            scrolled ? "scrolled" : "top"
          }`}
        >
          <Container className="d-flex align-items-center navbar-container">
            <Navbar.Brand
              as={Link}
              to="/"
              className="d-flex align-items-center"
            >
              <img
                src={getLogo()}
                alt="Logo"
                style={{ width: "100px", marginRight: "14px" }}
              />
              <p
                className={`custom-career ${scrolled ? "scrolled" : ""}`}
                style={{ color: getColor() }}
              >
                Career
              </p>
            </Navbar.Brand>

            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              onClick={() => setExpanded(expanded ? false : "expanded")}
            >
              <i
                className={`bi bi-list toggle-icon ${
                  scrolled ? "scrolled" : ""
                }`}
                style={{ color: getColor() }}
              ></i>
            </Navbar.Toggle>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto"></Nav>
              <Nav>
                <Nav.Link
                  as={Link}
                  to="/"
                  className={`my-navlink ${
                    isTealPage
                      ? scrolled
                        ? "scrolled"
                        : "nav-top"
                      : scrolled
                      ? "scrolled"
                      : ""
                  } ${
                    isHomePage
                      ? scrolled
                        ? "navlink-active"
                        : "linkteal-active"
                      : scrolled
                      ? "scrolled"
                      : ""
                  }  `}
                  onClick={() => setExpanded(false)}
                  style={{ color: getColor() }}
                >
                  Home{" "}
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/jobs"
                  className={`my-navlink ${
                    isTealPage
                      ? scrolled
                        ? "navlink-active"
                        : "linkteal-active"
                      : scrolled
                      ? "scrolled"
                      : ""
                  } ${
                    isHomePage
                      ? scrolled
                        ? ""
                        : "nav-top"
                      : scrolled
                      ? "scrolled"
                      : ""
                  } 
                  ${
                    isFormPage ? 
                      scrolled ? "tealnav-form": "tealnav-form"
                      : scrolled ? "" : ""
                  } 
                  `}
                  onClick={() => setExpanded(false)}
                  style={{ color: getColor() }}
                >
                  {" "}
                  Jobs{" "}
                </Nav.Link>
                <Nav.Link
                  as="a"
                  href="https://ashleyhotelgroup.com/"
                  className={`my-navlink ${
                    isTealPage
                      ? scrolled
                        ? "scrolled"
                        : "nav-top"
                      : scrolled
                      ? "scrolled"
                      : ""
                  } ${
                    isHomePage
                      ? scrolled
                        ? ""
                        : "nav-top"
                      : scrolled
                      ? "scrolled"
                      : ""
                  } 
                   ${
                    isFormPage ? 
                      scrolled ? "": "tealnav-form"
                      : scrolled ? "" : ""
                  } `}
                  onClick={() => setExpanded(false)}
                  target="_blank" // Optional: Open the link in a new tab
                  rel="noopener noreferrer"
                  style={{ color: getColor(), paddingRight: "0px" }}
                >
                  {" "}
                  Our Brands{" "}
                </Nav.Link>
                {/* <Nav.Link
                  as={Link}
                  to="/faq"
                  className={`my-navlink ${scrolled ? "scrolled" : ""}`}
                  style={{ color: getColor() }}
                  onClick={() => setExpanded(false)}
                >
                  {" "}
                  FAQ{" "}
                </Nav.Link> */}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      )}
      {/* expanded navbar for mobile version */}
      {expanded && (
        <Navbar className="secondary-navbar">
          <Container className="d-flex align-items-center justify-content-between">
            <Navbar.Brand
              as={Link}
              to="/"
              className="d-flex align-items-center"
            >
              <img
                src={white}
                alt="Logo"
                style={{ width: "100px", marginRight: "14px" }}
              />
              <p className="custom-career">Career</p>
            </Navbar.Brand>
            <i
              className="bi bi-x"
              onClick={() => setExpanded(false)}
              style={{ fontSize: "1.5rem", cursor: "pointer" }}
            ></i>
          </Container>
          <Nav className="secondary-nav-links d-flex flex-column mt-4">
            <Nav.Link
              as={Link}
              to="/"
              onClick={() => setExpanded(false)}
              style={{ color: isHomePage ? "#e4c17d" : "#fff" }}
            >
              Home
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/jobs"
              style={{ color: isJobsPage ? "#e4c17d" : "#fff" }}
              onClick={() => setExpanded(false)}
            >
              Jobs
            </Nav.Link>
            <Nav.Link
              as="a"
              href="https://ashleyhotelgroup.com/"
              style={{ color: "#FFF" }}
              onClick={() => setExpanded(false)}
              target="_blank" // Optional: Open the link in a new tab
              rel="noopener noreferrer"
            >
              {" "}
              Our Brands{" "}
            </Nav.Link>
            {/* <Nav.Link
              as={Link}
              to="/faq"
              style={{ color: getColor() }}
              onClick={() => setExpanded(false)}
            >
              FAQ
            </Nav.Link> */}
          </Nav>
        </Navbar>
      )}
    </>
  );
}

export default MyNavbar;
