import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  Dropdown,
  DropdownButton,
  Image,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import JobItem from "../components/JobItem";
import SearchBar from "../components/SearchBar";
import { debounce } from "lodash";
import axios from "../axios";
import hero from "../assets/image/work5.jpg";
import { BiSearchAlt } from "react-icons/bi";
import { FaHotel } from "react-icons/fa6";
import { BsBuildings } from "react-icons/bs";
import jobsImage from "../assets/img/herocrop.png";
import jobsBG from "../assets/img/as1meeting.jpg";
import bannerIMG from "../assets/img/bannerIMG2.png";

function Jobs() {
  const [jobs, setJobs] = useState([
    {
      id_jobs: 1,
      title: "Room Attendant",
      division: "Housekeeping",
      location: "Jakarta",
      jobType: "Daily Worker",
      placement: "Juno Jatinegara",
      description: [
        "Room Refresh : Enter guest rooms, make beds, and clean surfaces.",
        "Spot Checks : Ensure maintenance issues are reported promptly.",
        "Guest Interactions : Greet guests warmly and assist with requests.",
        "Final Touches : Ensure room looks perfect before leaving."
      ],
      qualification: [
        "Minimum Vocational High School.",
        "6 months experience in a similar role."
      ]
    },
    {
      id_jobs: 2,
      title: "IT Officer",
      division: "Information Technology",
      location: "Jakarta",
      jobType: "Fixed Term (Contract)",
      placement: "Head Office AHG",
      description: [
        "Monitor and maintain IT systems including networks and software.",
        "Troubleshoot hardware and software issues effectively.",
        "Assist staff with IT-related problems in a timely manner."
      ],
      qualification: [
        "Diploma in Information Technology or related field.",
        "Experience with troubleshooting and network support.",
        "Good problem-solving skills."
      ]
    },
    {
      id_jobs: 3,
      title: "Guest Service Officer",
      division: "Front Office",
      location: "Jakarta",
      jobType: "Fixed Term (Contract)",
      placement: "Ashley Tanah Abang",
      description: [
        "Greet and welcome guests upon arrival.",
        "Handle guest inquiries and reservations.",
        "Assist with check-ins and check-outs efficiently."
      ],
      qualification: [
        "Hospitality diploma or equivalent.",
        "1-year experience in front office or similar role.",
        "Strong communication skills."
      ]
    },
    {
      id_jobs: 4,
      title: "AC Technician",
      division: "Engineering",
      location: "Jakarta",
      jobType: "Daily Worker",
      placement: "Ashley Wahid Hasyim",
      description: [
        "Install and maintain air conditioning systems.",
        "Diagnose and repair issues with HVAC systems.",
        "Ensure systems meet safety and efficiency standards."
      ],
      qualification: [
        "Vocational certification in HVAC systems.",
        "At least 1-year experience in AC maintenance.",
        "Ability to read and understand technical manuals."
      ]
    },
    {
      id_jobs: 5,
      title: "Guest Service Officer",
      division: "Front Office",
      location: "Jakarta",
      jobType: "Fixed Term (Contract)",
      placement: "Yellow Bee Tangerang",
      description: [
        "Greet guests warmly and ensure they feel welcome.",
        "Handle guest complaints and provide solutions.",
        "Coordinate with housekeeping and maintenance as needed."
      ],
      qualification: [
        "Previous experience in hospitality preferred.",
        "Ability to handle stressful situations professionally.",
        "Good verbal communication skills."
      ]
    },
    {
      id_jobs: 6,
      title: "Corporate HR & Legal Officer",
      division: "Human Resources",
      location: "Jakarta",
      jobType: "Fixed Term (Contract)",
      placement: "Head Office AHG",
      description: [
        "Assist in HR policy development and compliance.",
        "Handle legal documentation and maintain records.",
        "Support recruitment and onboarding processes."
      ],
      qualification: [
        "Bachelor's degree in Law or Human Resources.",
        "Experience in HR and legal support.",
        "Strong attention to detail."
      ]
    }
  ]);

  const [loading, setLoading] = useState(false);

  const getListJobs = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/list/jobs`);
      setJobs(response.data);
    } catch (error) {
      console.log("Error Jobs List: ", error);
    } finally {
      setLoading(false);
    }
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFunction, setSelectedFunction] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedJobType, setSelectedJobType] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    console.log("search term : ", event.target.value);
  };

  const handleFunctionSelect = (event) => {
    setSelectedFunction(event.target.value);
    console.log("selected function : ", event.target.value);
  };

  const handleLocationSelect = (event) => {
    setSelectedLocation(event.target.value);
    console.log("selected location : ", event.target.value);
  };

  const handleJobTypeSelect = (event) => {
    setSelectedJobType(event.target.value);
    console.log("selected jobtype : ", event.target.value);
  };

  const filteredJobs = jobs.filter((job) => {
    return (
      (searchTerm === "" ||
        job.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        job.division.toLowerCase().includes(searchTerm.toLowerCase()) ||
        job.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
        job.jobType.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (selectedFunction === "" || job.division === selectedFunction) &&
      (selectedLocation === "" || job.placement === selectedLocation) &&
      (selectedJobType === "" || job.jobType === selectedJobType)
    );
  });

  const handleClear = () => {
    setSearchTerm("");
    setSelectedFunction("");
    setSelectedLocation("");
    setSelectedJobType("");
  };

  const debouncedGetListJobs = debounce(getListJobs, 500);

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setSearchTerm(params.get("searchTerm") || "");
    setSelectedFunction(params.get("selectedFunction") || "");
    setSelectedLocation(params.get("selectedLocation") || "");
    setSelectedJobType(params.get("selectedJobType") || "");
  }, [location.search]);

  useEffect(() => {
    // debouncedGetListJobs();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
       {/* Hero Jobs */}
      <div className="banner-jobs-background-wrapper">
        <div className="banner-jobs-background">
          <div className="background-overlay" style={{ backgroundImage: `url(${hero})` }}></div>
          <div className="container-lg hero-jobs-content">
            <div className="hero-jobs-text">
              <h1 className="jobs-title">All Jobs</h1>
              <p className="jobs-subtitle">6 Open Jobs</p>
            </div>
          </div>

          {/* Search and Filter Bar New */}
          <div className="search-filter-bar-trans-jobs">
            <Row className="justify-content-center">
              <Col lg={12} xl={12}>
                <Form
                  className="row align-items-center justify-content-center"
                  action="/jobs"
                  method="get"
                >
                  <input type="hidden" name="search" value="true" />
                  {/* Search */}
                  <Form.Group className="mb-5 text-center">
                    <Row className="justify-content-center">
                      <Col xs={12} md={4}>
                        <InputGroup className="search-input-group">
                          <Form.Control
                            type="text"
                            name="key"
                            id="key"
                            maxLength="50"
                            placeholder="Start your search"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            autoComplete="off"
                            className="no-border radius-50 font-sm search-input-comp"
                          />
                          <div className="search-icon-wrapper">
                            <BiSearchAlt className="search-icon" />
                          </div>
                        </InputGroup>
                      </Col>
                    </Row>
                  </Form.Group>
                  {/* Filters */}
                  <Form.Group className="text-center">
                    <Row className="justify-content-center">
                      <Col xs={12} md={8}>
                        <Row className="justify-content-center">
                          {/* Department */}
                          <Col
                            xs={12}
                            sm={4}
                            className=" d-flex flex-row align-items-center padding-right-0"
                          >
                            <InputGroup className="inner-group radius-50 d-flex flex-row justify-content-center margin-top-0">
                              <InputGroup.Text className="no-border">
                                <i className="bi bi-grid"></i>
                              </InputGroup.Text>
                              <Form.Select
                                name="directorates"
                                id="directorates"
                                onChange={handleFunctionSelect}
                                value={selectedFunction}
                                className="select2 no-border font-sm"
                                data-placeholder="Select Function"
                              >
                                <option value="" disabled selected>
                                  Department
                                </option>
                                <option value="">All Department</option>
                                <option value="Information Technology">
                                  Information Technology
                                </option>
                                <option value="Front Office">Front Office</option>
                                <option value="Engineering">Engineering</option>
                                <option value="Housekeeping">Housekeeping</option>
                                <option value="Human Resources">
                                  Human Resources
                                </option>
                              </Form.Select>
                            </InputGroup>
                          </Col>
                          {/* Location */}
                          <Col
                            xs={6}
                            sm={4}
                            className="d-flex flex-row align-items-center pe-0"
                          >
                            <InputGroup className="inner-group radius-50 d-flex flex-row justify-content-center margin-top-0">
                              <InputGroup.Text className="no-border">
                                {/* <i className="bi bi-geo-alt"></i> */}
                                {/* <FaHotel /> */}
                                <BsBuildings />
                              </InputGroup.Text>
                              <Form.Select
                                name="location"
                                id="location"
                                onChange={handleLocationSelect}
                                value={selectedLocation}
                                className="select2 no-border font-sm"
                                data-placeholder="Select Location"
                              >
                                <option value="" disabled selected>
                                  Placement
                                </option>
                                <option value="">Any Placement</option>
                                <option value="Head Office AHG">Head Office AHG</option>
                                <option value="Ashley Wahid Hasyim">Ashley Wahid Hasyim</option>
                                <option value="Ashley Sabang">Ashley Sabang</option>
                                <option value="Ashley Tanah Abang">Ashley Tanah Abang</option>
                                <option value="Ashley Tang">Ashley Tang</option>
                                <option value="Ashley Tugu Tani">Ashley Tugu Tani</option>
                                <option value="Stanley Wahid Hasyim">Stanley Wahid Hasyim</option>
                                <option value="Juno Tanah Abang">Juno Tanah Abang</option>
                                <option value="Juno Jatinegara">Juno Jatinegara</option>
                                <option value="Yellow Bee Tangerang">Yellow Bee Tangerang</option>
                                <option value="Yellow Bee Tanah Abang">Yellow Bee Tanah Abang</option>

                              </Form.Select>
                            </InputGroup>
                          </Col>
                          {/* Job Type */}
                          <Col
                            xs={6}
                            sm={4}
                            className=" d-flex flex-row align-items-center padding-left-12"
                          >
                            <InputGroup className=" inner-group radius-50 d-flex flex-row justify-content-center margin-top-0">
                              <InputGroup.Text className="no-border">
                                <i className="bi bi-briefcase"></i>
                              </InputGroup.Text>
                              <Form.Select
                                name="commitment"
                                id="jobtype"
                                onChange={handleJobTypeSelect}
                                value={selectedJobType}
                                className="select2 no-border font-sm"
                                data-placeholder="Select Job Type"
                              >
                                <option
                                  value=""
                                  disabled
                                  selected
                                  className="color-gray"
                                >
                                  Job Type
                                </option>
                                <option value="">All Job Types</option>
                                <option value="Fixed Term (Contract)">
                                  Fixed Term (Contract)
                                </option>
                                <option value="Daily Worker">Daily Worker</option>
                              </Form.Select>
                            </InputGroup>
                          </Col>
                        </Row>
                      </Col>
                      {/* Button */}
                      <Col
                        xs={12}
                        sm={6}
                        md={2}
                        className="d-flex align-items-center justify-content-center"
                      >
                        <div
                          type="submit"
                          className="w-100 h-100 text-center button-temp-jobs clear-button pb-0"
                          onClick={handleClear}
                        >
                          <i
                            className="bi bi-eraser-fill me-2"
                            style={{ fontSize: "1.05rem" }}
                          ></i>
                          <span className="clear-text">Clear</span>
                        </div>
                      </Col>
                    </Row>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <div className="bg-light1" style={{ paddingBottom: "10%" }}>
        <Container className="mb-5 mt-3 job-amount text-center">
          {/* {searchTerm || selectedFunction || selectedLocation || selectedJobType
            ? `${filteredJobs.length} jobs available`
            : `${jobs.length} jobs in various categories`} */}
            {searchTerm || selectedFunction || selectedLocation || selectedJobType
            ? `${filteredJobs.length} jobs available`
            : `Showing 1-${jobs.length} results out of total ${jobs.length} Open Jobs`}
        </Container>
        {/* Jobs List Row */}
        <Row className="job-list-wrap mx-auto">
          {filteredJobs.map((job) => (
            <JobItem key={job.id_jobs} job={job} />
          ))}
        </Row>
      </div>
    </>
  );
}

export default Jobs;
