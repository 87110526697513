import axios from "axios";

// Create an instance of axios
const axiosInstance = axios.create({
  baseURL: "https://apimeals.ashleyhotelgroup.co.id/", // Replace with your API base URL
  headers: {
    "Content-Type": "text/json",
  },
});

export default axiosInstance;
