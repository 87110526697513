import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import HomePage from "./pages/HomeScreen";
import JobsPage from "./pages/Jobs";
import JobDetail from "./pages/JobDetail";
import JobForm from "./pages/JobForm";
import FAQPage from "./pages/FAQ";

import MyNavbar from "./components/MyNavbar";
import Footer from "./components/Footer";

import "@fancyapps/fancybox/dist/jquery.fancybox.min.css";

export default function App() {
  return (
    <Router>
      <MyNavbar />

      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/jobs" component={JobsPage} />
        <Route path="/job/:id/form" component={JobForm} />
        <Route path="/job/:id" component={JobDetail} />
        <Route path="/faq" component={FAQPage} />
      </Switch>
      <Footer />
    </Router>
  );
}
