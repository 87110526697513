import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import { debounce } from "lodash";
import axios from "../axios";
import PROFILE from "../assets/img/mbti-profile.png";
import SHARE from "../assets/img/mbti-share.png";
import XLS from "../assets/icons/xls1.png";

function JobForm() {
  //var idx = id - 1;
  const { id } = useParams();

  //data dummy
  const [jobs, setJobs] = useState([
    {
      id_jobs: 1,
      title: "Room Attendant",
      division: "Housekeeping",
      location: "Jakarta",
      jobType: "Daily Worker",
      placement: "Juno Jatinegara",
      description: [
        "Room Refresh : Enter guest rooms, make beds, and clean surfaces.",
        "Spot Checks : Ensure maintenance issues are reported promptly.",
        "Guest Interactions : Greet guests warmly and assist with requests.",
        "Final Touches : Ensure room looks perfect before leaving."
      ],
      qualification: [
        "Minimum Vocational High School.",
        "6 months experience in a similar role."
      ]
    },
    {
      id_jobs: 2,
      title: "IT Officer",
      division: "Information Technology",
      location: "Jakarta",
      jobType: "Fixed Term (Contract)",
      placement: "Head Office AHG",
      description: [
        "Monitor and maintain IT systems including networks and software.",
        "Troubleshoot hardware and software issues effectively.",
        "Assist staff with IT-related problems in a timely manner."
      ],
      qualification: [
        "Diploma in Information Technology or related field.",
        "Experience with troubleshooting and network support.",
        "Good problem-solving skills."
      ]
    },
    {
      id_jobs: 3,
      title: "Guest Service Officer",
      division: "Front Office",
      location: "Jakarta",
      jobType: "Fixed Term (Contract)",
      placement: "Ashley Tanah Abang",
      description: [
        "Greet and welcome guests upon arrival.",
        "Handle guest inquiries and reservations.",
        "Assist with check-ins and check-outs efficiently."
      ],
      qualification: [
        "Hospitality diploma or equivalent.",
        "1-year experience in front office or similar role.",
        "Strong communication skills."
      ]
    },
    {
      id_jobs: 4,
      title: "AC Technician",
      division: "Engineering",
      location: "Jakarta",
      jobType: "Daily Worker",
      placement: "Ashley Wahid Hasyim",
      description: [
        "Install and maintain air conditioning systems.",
        "Diagnose and repair issues with HVAC systems.",
        "Ensure systems meet safety and efficiency standards."
      ],
      qualification: [
        "Vocational certification in HVAC systems.",
        "At least 1-year experience in AC maintenance.",
        "Ability to read and understand technical manuals."
      ]
    },
    {
      id_jobs: 5,
      title: "Guest Service Officer",
      division: "Front Office",
      location: "Jakarta",
      jobType: "Fixed Term (Contract)",
      placement: "Yellow Bee Tangerang",
      description: [
        "Greet guests warmly and ensure they feel welcome.",
        "Handle guest complaints and provide solutions.",
        "Coordinate with housekeeping and maintenance as needed."
      ],
      qualification: [
        "Previous experience in hospitality preferred.",
        "Ability to handle stressful situations professionally.",
        "Good verbal communication skills."
      ]
    },
    {
      id_jobs: 6,
      title: "Corporate HR & Legal Officer",
      division: "Human Resources",
      location: "Jakarta",
      jobType: "Fixed Term (Contract)",
      placement: "Head Office AHG",
      description: [
        "Assist in HR policy development and compliance.",
        "Handle legal documentation and maintain records.",
        "Support recruitment and onboarding processes."
      ],
      qualification: [
        "Bachelor's degree in Law or Human Resources.",
        "Experience in HR and legal support.",
        "Strong attention to detail."
      ]
    }
  ]);
  const [groupQuest, setGroupQuest] = useState([
    { id_group: 1, group_name: "Additional Information" },
    { id_group: 2, group_name: "English Skills" },
    // { id_group: 3, group_name: "Excel Proficiency" },
    // { id_group: 4, group_name: "Ability To Work At Jakarta Office" },
    // { id_group: 5, group_name: "Total Years Of Experience" },
    // { id_group: 6, group_name: "Work Experience" },
    // { id_group: 7, group_name: "Notice Period" },
  ]);

  const [questionList, setQuestionList] = useState([
    {
      id_quest: 1,
      question: "How did you first become aware of this job opportunity?",
      type_quest_id: 2,
      hotel_id: "1,2,3,4,5,6,7,8,9,10,11,12,15,100",
      dept_id: "0",
      pos_id: 0,
      group_id: 1,
    },
    {
      id_quest: 2,
      question: "How is your confidence communicating using English?",
      type_quest_id: 2,
      hotel_id: "1,2,3,4,5,6,7,8,9,10,11,12,15,100",
      dept_id: "0",
      pos_id: 0,
      group_id: 2,
    },
    // {
    //   id_quest: 3,
    //   question: "How would you rate your proficiency in using Excel?",
    //   type_quest_id: 2,
    //   hotel_id: "1,2,3,4,5,6,7,8,9,10,11,12,15",
    //   dept_id: "3",
    //   pos_id: 3,
    //   group_id: 3,
    // },
    // {
    //   id_quest: 4,
    //   question:
    //     "How many years of professional experience do you have in total?",
    //   type_quest_id: 1,
    //   hotel_id: "",
    //   dept_id: "0",
    //   pos_id: 0,
    //   group_id: 5,
    // },
    // {
    //   id_quest: 5,
    //   question: "What is your total work experience?",
    //   type_quest_id: 1,
    //   hotel_id: "",
    //   dept_id: "0",
    //   pos_id: 0,
    //   group_id: 6,
    // },
    // {
    //   id_quest: 6,
    //   question: "What is your Current Notice Period?",
    //   type_quest_id: 1,
    //   hotel_id: "",
    //   dept_id: "0",
    //   pos_id: 0,
    //   group_id: 7,
    // },
    // {
    //   id_quest: 7,
    //   question:
    //     "Are you willing to work at the Ashley Hotel Group office in Jakarta?",
    //   type_quest_id: 2,
    //   hotel_id: "",
    //   dept_id: "0",
    //   pos_id: 2,
    //   group_id: 4,
    // },
    // {
    //   id_quest: 8,
    //   question: "Are you willing to work for 3 days WFO and 2 days WFA?",
    //   type_quest_id: 2,
    //   hotel_id: "",
    //   dept_id: "0",
    //   pos_id: 2,
    //   group_id: 4,
    // },
  ]);

  const [optionList, setOptionList] = useState([
    {
      id_option: 1,
      option_label: "Instagram @lifeatashleyhotels",
      quest_id: 1,
    },
    { id_option: 2, option_label: "LinkedIn", quest_id: 1 },
    {
      id_option: 3,
      option_label: "Event (Company Visit , Webinar, or Conference)",
      quest_id: 1,
    },
    {
      id_option: 4,
      option_label: "Others (Referral, Browser, etc)",
      quest_id: 1,
    },
    { id_option: 5, option_label: "Beginner", quest_id: 2 },
    { id_option: 6, option_label: "Intermediate", quest_id: 2 },
    { id_option: 7, option_label: "Advance", quest_id: 2 },
    { id_option: 8, option_label: "Beginner", quest_id: 3 },
    { id_option: 9, option_label: "Intermediate", quest_id: 3 },
    { id_option: 10, option_label: "Advance", quest_id: 3 },
    { id_option: 11, option_label: "Yes", quest_id: 7 },
    { id_option: 12, option_label: "No", quest_id: 7 },
    { id_option: 13, option_label: "Yes", quest_id: 8 },
    { id_option: 14, option_label: "No", quest_id: 8 },
  ]);

  const [loading, setLoading] = useState(false);
  //const [job, setJob] = useState(null);

  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    resume: "",
    fullName: "",
    email: "",
    phone: "",
    location: "",
    company: "",
    linkedin: "",
    portfolio: "",
    website: "",
    additionalInfo: "",
    personalityTestLink: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    //console.log("formData: ", formData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (step < 3) {
      setStep(step + 1);
    } else {
      // Handle final submission
      console.log("Form data submitted:", formData);
    }
  };

  const history = useHistory();

  const handleBack = () => {
    history.push(`/jobs`);
  };

  const getForm = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/api/job/{id}`);
      //setJob(response.data);
    } catch (error) {
      console.log("Error Jobs Detauk: ", error);
    } finally {
      setLoading(false);
    }
  };

  const debounceGetForm = debounce(getForm, 500);

  //   useEffect(() => {
  //     debounceGetForm();
  //   }, []);

  const job = jobs.find((job) => job.id_jobs === parseInt(id));

  //display questions that has the same position_id in jobs
  const renderQuestions = () => {
    const renderedGroups = new Set();

    return questionList
      .filter(
        (question) =>
          question.pos_id === 0 || question.pos_id === job.position_id
      )
      .map((question) => {
        const group = groupQuest.find(
          (group) => group.id_group === question.group_id
        );
        const options = optionList.filter(
          (option) => option.quest_id === question.id_quest
        );

        const groupTitle = !renderedGroups.has(group.id_group) ? (
          <h6 className="question-title" key={group.id_group}>
            {group.group_name}
          </h6>
        ) : null;

        renderedGroups.add(group.id_group);

        return (
          <div key={question.id_quest}>
            {groupTitle}
            <Form.Group>
              <Form.Label>{question.question}</Form.Label>
              {question.type_quest_id === 1 ? (
                <Form.Control
                  as="textarea"
                  rows={3}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [question.id_quest]: e.target.value,
                    })
                  }
                />
              ) : (
                <div>
                  {options.map((option) => (
                    <Form.Check
                      key={option.id_option}
                      type="radio"
                      label={option.option_label}
                      name={`question-${question.id_quest}`}
                      value={option.option_label}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          [question.id_quest]: e.target.value,
                        })
                      }
                    />
                  ))}
                </div>
              )}
            </Form.Group>
          </div>
        );
      });
  };

  const renderStepContent = () => {
    switch (step) {
      //personal info and quetions
      case 1:
        return (
          <>
            <h4 className="fw-bold mt-4 mb-2">Personal Info</h4>
            <Form.Group className="mb-2 mt-4">
              <Form.Label>Resume/CV</Form.Label>
              <Form.Control type="file" name="resume" onChange={handleChange} />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                type="text"
                name="fullName"
                value={formData.fullName}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>Current Location</Form.Label>
              <Form.Control
                type="text"
                name="location"
                value={formData.location}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>Current Company</Form.Label>
              <Form.Control
                type="text"
                name="company"
                value={formData.company}
                onChange={handleChange}
              />
            </Form.Group>
            <h4 className="fw-bold mt-4">Links</h4>
            <p
              className="text-secondary mb-4"
              style={{ margin: 0, fontSize: "15px" }}
            >
              (Optional)
            </p>
            <Form.Group className="mb-2">
              <Form.Label>LinkedIn URL</Form.Label>
              <Form.Control
                type="text"
                name="linkedin"
                value={formData.linkedin}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>Portfolio URL</Form.Label>
              <Form.Control
                type="text"
                name="portfolio"
                value={formData.portfolio}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>Other Website</Form.Label>
              <Form.Control
                type="text"
                name="website"
                value={formData.website}
                onChange={handleChange}
              />
            </Form.Group>
            {renderQuestions()}
            <h6 className="question-title">Cover Letter</h6>
            <Form.Group>
              {/* <Form.Label className="mt-1 mb-3">
                Additional Information
              </Form.Label> */}
              <Form.Control
                as="textarea"
                rows={3}
                name="additionalInfo"
                placeholder="Add a cover letter or anything else you want to share (Optional)"
                value={formData.additionalInfo}
                onChange={handleChange}
              />
            </Form.Group>
            {/* <p className="required">* Required</p> */}
          </>
        );
      //Job Application Form
      case 2:
        return (
          <>
            <h5 className="fw-bold mt-4" style={{ color: "#00414e" }}>
              Application Form
            </h5>
            <p>Please fill out and upload the completed application form.</p>

            <div className="mt-3 d-flex flex-column align-items-center align-items-sm-start">
              <div className="mt-2 d-flex flex-column align-items-center application-wrapper">
                <img
                  src={XLS}
                  alt="Application Form Thumbnail"
                  className="application-img"
                />
                <p className="application-text mt-2">
                  APPLICATION FORM - AHG
                  <br />
                  (5 Sheets).xlsx
                </p>
              </div>
              <div className="mt-2 d-flex justify-content-center download-container">
                <div className="download-wrapper">
                  <a
                    href="/assets/src/APPLICATION FORM - AHG (5 sheets)_.xlsx"
                    download
                    className="button btn-apply1 mt-3"
                  >
                    Download
                  </a>
                </div>
              </div>
            </div>

            <div className="mt-5">
              <Form.Group controlId="formFile" className="mb-4">
                <Form.Label>Upload Completed Application Form</Form.Label>
                <Form.Control type="file" />
              </Form.Group>
            </div>
          </>
        );
      //MBTI Personality test link
      case 3:
        return (
          <>
            <h5 className="fw-bold mt-4" style={{ color: "#00414e" }}>
              Personality Test
            </h5>
            <p>
              Take the test at{" "}
              <a
                href="https://16personalities.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://16personalities.com/
              </a>{" "}
              and fill the field below with the result link.
            </p>
            <p>
              1. Go to{" "}
              <a
                href="https://16personalities.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://16personalities.com/
              </a>
              .<br />
              2. Select 'Take the Test' or 'Personality Test'.
              <br />
              3. Answer all the questions honestly according to your true self.
              <br />
              4. Input your email to get the results.
              <br />
              {
                "5. Open the email with your results and click 'View Your Profile' or Go To Profile -> Overview."
              }
              <br />
              6. Click 'Share Your Profile' and copy the result link.
            </p>

            <div className="mbti-img-container">
              <img src={PROFILE} className="mbti-img mb-4" />
              <img src={SHARE} className="mbti-img" />
            </div>

            <Form.Group className="mb-4 mt-4">
              <Form.Label className="mt-3">
                MBTI Personality Test Result Link
              </Form.Label>
              <Form.Control
                type="text"
                name="personalityTestLink"
                placeholder="e.g. https://www.16personalities.com/profiles/id"
                value={formData.personalityTestLink}
                onChange={handleChange}
              />
            </Form.Group>
          </>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (job) {
      console.log("Job found:", job);
    } else {
      console.log("Job not found");
    }
  }, [job]);
  return (
    <div className="bg-light1">
      {/* Header Job Info */}
      <div className="header-detail-bg">
        <div className="container-lg header-detail d-flex justify-content-between align-items-center flex-column flex-sm-row">
          <div>
            <h1>{job.title}</h1>
            <div className="job-meta d-flex mt-3 mb-2">
              <div className="location-form d-flex align-items-center ">
                <i className="bi bi-geo-alt me-1"></i>
                <p className="mb-0">{job.location}</p>
                <p className="mb-0 ms-2 me-2">{"-"}</p>
              </div>
              <div className="placement-form">{job.placement}</div>
            </div>
            <div className="text-start text-sm-end mt-2 mt-md-0 d-flex">
              <strong className="division-form">{job.division}</strong>
              <p className="text-teal mb-0 ms-2 me-2">{"/"}</p>
              <div className="job-type-form mb-0">{job.jobType}</div>
            </div>
          </div>
        </div>
      </div>
      {/* Job Forms */}
      <div className="container-md d-flex flex-row justify-content-center remove-padding mt-3">
        <div className="apply-form">
          <h1>Submit Your Application</h1>

          <div className="step-indicator">
            {[1, 2, 3].map((num) => (
              <div
                key={num}
                className={`step ${num === step ? "active" : ""}`}
                onClick={() => setStep(num)}
                style={{ cursor: "pointer" }}
              >
                <div className="circle">{num}</div>
                <div className="label">
                  {num === 1 && "Personal Info"}
                  {num === 2 && "Application Form"}
                  {num === 3 && "Personality Test"}
                </div>
              </div>
            ))}
          </div>
          <Form onSubmit={handleSubmit}>
            {renderStepContent()}
            <div className="mt-3 d-flex justify-content-end">
              {/* <div className="dot-indicator d-none d-md-block">
                {[1, 2, 3].map((num) => (
                  <span
                    key={num}
                    className={`dot ${num === step ? "active" : ""}`}
                    onClick={() => setStep(num)}
                  />
                ))}
              </div> */}
              <div className="d-flex flew-column justify-content-center mt-1">
                {step > 1 && (
                  <div
                    onClick={() => setStep(step - 1)}
                    className="me-2 prev-button"
                  >
                    Back
                  </div>
                )}
                <Button type="submit" className="submit-button">
                  {step === 3 ? "Submit Application" : "Next"}
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default JobForm;
